<template>
    <div class="workbenchbox">
      <div class="workbenchbox-content autobox2">
        <slot></slot>
      </div>
    </div>
</template>

<style lang="scss" scoped >
@import '@/styles/color/value.scss';
.workbenchbox{
  box-sizing: border-box;
  width: 100%;
  padding: 0;
  min-height: calc(100vh - 88px);
  box-sizing: border-box;
  background-color: $bg-transition;
  &-content{
    // height: calc(100vh - 88px);
    width: 1400px;
    margin: 0 auto;
  }
}
</style>