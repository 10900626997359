<template>
  <workbenchbox>
    <choose class="mb-12" @Emit="butemit"></choose>
    <enterprise v-if="state.Index==0"></enterprise>
    <individual v-if="state.Index==1"></individual>
  </workbenchbox>
</template>
<script setup>
import { reactive, ref, unref } from "vue";
import workbenchbox from "@/components/layout/workbenchbox.vue";
import choose from "./components/choose.vue";
import individual from "./components/individual.vue";//个人
import enterprise from "./components/enterprise.vue";//企业
const state = reactive({
  Index:0,//当前选中下标
});
// 按钮返回
const butemit=((el)=>{
    state.Index=el
})
</script>
<style lang="scss" scoped >
@import "@/styles/general/element.scss"; //element 样式重置
@import "@/styles/general/element/table.scss"; //element 列表样式重置
@import "@/styles/general/element/pagination1.scss"; //element 分页样式重置
</style>